export function setCookie(key, value, time) {
  document.cookie = `${key}=${value}; max-age=${time}; path=/`;
}

export function checkCookie(key) {
  let valueList = document.cookie.split("; ").filter((item) => {
    if (item.indexOf(key) >= 0) {
      return item;
    }
  });
  if (valueList.length > 0) {
    const value = valueList[0].replace(key + "=", "");
    if (value) {
      return { ok: true, value: value };
    } else {
      return { ok: false };
    }
  } else {
    return { ok: false };
  }
}

export function deleteCookie(key) {
  document.cookie = `${key}=null; max-age=0`;
}

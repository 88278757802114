import React, { useEffect, useState } from "react";
import { deleteCookie } from "../lib/cookieUtilits";

function AdminPanel() {
  const [slideIsShown, setSlideIsShown] = useState(false);
  const [acceptButton, setAcceptButton] = useState(false);

  const [users, setUsers] = useState([]);
  const [eventData, setEventData] = useState({});

  const [newDate, setNewDate] = useState("");
  const [newAdress, setNewAdress] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setSlideIsShown(true);
    }, 5000);

    fetch("/api/db-admin.php", {
      method: "POST",
      body: JSON.stringify({
        action: "get_users",
      }),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "https://igra-vibor.ru",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          setUsers(data.data);
        }
      });

    fetch("/api/db-events.php", {
      method: "POST",
      body: JSON.stringify({
        action: "get_event",
      }),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "https://igra-vibor.ru",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEventData(data.data);
      });
  }, []);

  function resetUsers() {
    setUsers([]);
    setAcceptButton(false);

    fetch("/api/db-admin.php", {
      method: "POST",
      body: JSON.stringify({
        action: "reset_users",
      }),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "https://igra-vibor.ru",
      },
    });
  }

  function deleteUser(id) {
    document.getElementById(id).style.display = "none";

    fetch("/api/db-admin.php", {
      method: "POST",
      body: JSON.stringify({
        action: "delete_user",
        id: id,
      }),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "https://igra-vibor.ru",
      },
    });
  }

  function changeEventData() {
    setEventData({
      date: newDate,
      adress: newAdress,
      password: newPassword,
    });

    fetch("/api/db-events.php", {
      method: "POST",
      body: JSON.stringify({
        action: "change_event",
        date: newDate,
        adress: newAdress,
        password: newPassword,
      }),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "https://igra-vibor.ru",
      },
    });
  }

  if (!slideIsShown) {
    return (
      <div className="notify_title slide-title">
        <h1>Вы администратор.</h1>
      </div>
    );
  } else {
    return (
      <div className="form_container">
        <button
          className="start_button"
          onClick={() => {
            deleteCookie("a_token");
            window.location.reload();
          }}
        >
          Выйти из аккаунта
        </button>
        <h1>Заявки:</h1>
        <ul className="admin_users">
          <li>
            <span>Имя</span>
            <span>Email</span>
            <span>Роль</span>
            <span>Социальная роль</span>
            <span>Скрытая роль</span>
          </li>
          {users.length > 0 ? (
            users.map((user) => {
              return (
                <li id={user["id"]}>
                  <span>{user["name"]}</span>
                  <span>{user["email"]}</span>
                  <span>{user["role"]}</span>
                  <span>{user["social_role"]}</span>
                  <span>{user["hidden_role"]}</span>
                  <button
                    className="delete_button"
                    onClick={() => {
                      deleteUser(user["id"]);
                    }}
                  >
                    &#10006;
                  </button>
                </li>
              );
            })
          ) : (
            <li>
              <span>Нет заявок</span>
            </li>
          )}
        </ul>
        {!acceptButton ? (
          <button
            className="start_button"
            onClick={() => {
              setAcceptButton(true);
            }}
          >
            Очистить
          </button>
        ) : (
          <div className="notify_buttons">
            <button
              className="start_button"
              onClick={() => {
                resetUsers();
              }}
            >
              Подтвердить
            </button>

            <button
              onClick={() => {
                setAcceptButton(false);
              }}
            >
              Отмена
            </button>
          </div>
        )}

        <h1>Информация о событии:</h1>
        <div className="event_info">
          <div className="event_info_item">
            <span>Дата</span>
            <p>{eventData["date"]}</p>
          </div>
          <div className="event_info_item">
            <span>Адрес</span>
            <p
              style={{
                "border-bottom": "1px solid #fff",
                "padding-bottom": "5px",
              }}
            >
              {eventData["adress"]}
            </p>
          </div>
          <div className="event_info_item">
            <span>Пароль</span>
            <p>{eventData["password"]}</p>
          </div>
        </div>
        <div className="form_container">
          <h1>Изменить информацию о событии</h1>
          <input
            placeholder="Дата"
            type="text"
            onChange={(e) => {
              setNewDate(e.target.value);
            }}
          />
          <input
            placeholder="Адрес"
            type="text"
            onChange={(e) => {
              setNewAdress(e.target.value);
            }}
          />
          <input
            placeholder="Кодовое слово"
            type="text"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
          <button
            className="start_button"
            onClick={() => {
              if (newDate && newAdress && newPassword) {
                changeEventData();
              }
            }}
          >
            Изменить
          </button>
        </div>
      </div>
    );
  }
}

export default AdminPanel;

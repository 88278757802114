import React, { useEffect, useState } from "react";

function RoleSelect({ globalProfile }) {
  const [availableRoles, setAvailableRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [notify, setNotify] = useState(false);
  const [roleName, setRoleName] = useState("");

  const [socialRoles, setSocialRoles] = useState([]);
  const [hiddenRoles, setHiddenRoles] = useState([]);
  const [socialRoleIsSelected, setSocialRoleIsSelected] = useState(false);
  const [selectedExtraRole, setSelectedExtraRole] = useState("");

  const [extraRoleSlideIsShown, setExtraRoleSlideIsShown] = useState(false);
  const [roleSlideIsShown, setRoleSlideIsShown] = useState(false);
  const [isPrepaired, setIsPrepaired] = useState(false);

  useEffect(() => {
    if (!globalProfile["role_id"]) {
      fetch("/api/db-roles.php", {
        method: "POST",
        body: JSON.stringify({
          action: "get_roles",
        }),
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "https://igra-vibor.ru",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAvailableRoles(data.data);
        });

      setTimeout(() => {
        setRoleSlideIsShown(true);
      }, 10000);
      setTimeout(() => {
        document.querySelectorAll(".prepaire_anim").forEach((el) => {
          el.classList.add("active");
        });
      }, 10200);
    } else {
      if (!globalProfile["social_role"]) {
        fetch("/api/db-roles.php", {
          method: "POST",
          body: JSON.stringify({
            action: "get_social_roles",
            role_id: globalProfile["role_id"],
          }),
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "https://igra-vibor.ru",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setSocialRoles(data.data);
          });

        fetch("/api/db-roles.php", {
          method: "POST",
          body: JSON.stringify({
            action: "get_hidden_roles",
            role_id: globalProfile["role_id"],
          }),
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "https://igra-vibor.ru",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setHiddenRoles(data.data);
          });

        setTimeout(() => {
          setExtraRoleSlideIsShown(true);
        }, 5000);
      } else {
        setSocialRoleIsSelected(true);

        fetch("/api/db-roles.php", {
          method: "POST",
          body: JSON.stringify({
            action: "get_hidden_roles",
            role_id: globalProfile["role_id"],
          }),
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "https://igra-vibor.ru",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setHiddenRoles(data.data);
          });

        setTimeout(() => {
          setExtraRoleSlideIsShown(true);
        }, 5000);
      }
    }
  }, []);

  function selectRole(roleId, buttonEl, extra = false) {
    const buttons = document.querySelectorAll("li");
    if (
      document.querySelector(".start_button").classList.contains("disabled")
    ) {
      document.querySelector(".start_button").classList.remove("disabled");
    }

    buttons.forEach((button) => {
      if (button != buttonEl && !button.classList.contains("title")) {
        button.classList.add("disabled");
      } else if (button == buttonEl) {
        button.classList.remove("disabled");
      }
    });

    if (!extra) {
      setSelectedRole(roleId);
    } else {
      setSelectedExtraRole(roleId);
    }
  }

  function acceptSelectedRole(roleId, extra = "") {
    if (!extra) {
      fetch("/api/db-roles.php", {
        method: "POST",
        body: JSON.stringify({
          action: "select_role",
          role_id: roleId,
          token: globalProfile["a_token"],
        }),
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "https://igra-vibor.ru",
        },
      }).then(() => {
        window.location.reload();
      });
    } else {
      if (extra === "social") {
        fetch("/api/db-roles.php", {
          method: "POST",
          body: JSON.stringify({
            action: "select_extra_role",
            role_type: "social",
            role_id: roleId,
            token: globalProfile["a_token"],
          }),
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "https://igra-vibor.ru",
          },
        }).then(() => {
          setSelectedExtraRole("");
          const buttons = document.querySelectorAll("li");
          document.querySelector(".start_button").classList.add("disabled");

          buttons.forEach((button) => {
            button.classList.remove("disabled");
          });
          setSocialRoleIsSelected(true);
        });
      } else {
        fetch("/api/db-roles.php", {
          method: "POST",
          body: JSON.stringify({
            action: "select_extra_role",
            role_type: "hidden",
            role_id: roleId,
            token: globalProfile["a_token"],
          }),
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "https://igra-vibor.ru",
          },
        }).then(() => {
          window.location.reload();
        });
      }
    }
  }

  if (notify) {
    return (
      <div className="notify_container-block">
        <div className="notify_title">
          <span>Предупреждение</span>
          <h1>
            <span
              style={{
                "font-weight": "500",
                "font-size": "inherit",
                color: "inherit",
              }}
            >
              Выбранная профессия:{" "}
            </span>
            {roleName}.
          </h1>
          <h2>После выбора профессии вы не сможете вернуться обратно!</h2>
        </div>
        <div className="notify_container-block_buttons">
          <button
            className="notify_container-block_button"
            onClick={() => {
              setNotify(false);
            }}
          >
            Отмена
          </button>
          <button
            className="notify_container-block_button"
            onClick={() => {
              acceptSelectedRole(selectedRole);
            }}
          >
            ОК
          </button>
        </div>
      </div>
    );
  } else if (!globalProfile["role_id"]) {
    if (isPrepaired) {
      return (
        <div className="main_container">
          <ul className="select_container">
            <li className="title">
              <span>Выберите роль</span>
            </li>
            {availableRoles.length > 0 ? (
              availableRoles.map((availableRole) => {
                return (
                  <li
                    onClick={(e) => {
                      selectRole(availableRole[0], e.currentTarget);
                      setRoleName(availableRole[1]);
                    }}
                  >
                    <img src="/img/icon.png" />
                    <span>{availableRole[1]}</span>
                  </li>
                );
              })
            ) : (
              <li className="disabled">Нет доступных ролей</li>
            )}
          </ul>
          <button
            className="start_button disabled"
            onClick={() => {
              if (selectedRole) {
                setNotify(true);
              }
            }}
          >
            Сделать выбор
          </button>
        </div>
      );
    } else if (!roleSlideIsShown) {
      return (
        <div className={"notify_title"}>
          <h1 style={{ animation: "slide-appearance 6s forwards ease-in-out" }}>
            Часто ли вы задумывались о своей жизни?
          </h1>
          <h1 style={{ animation: "slide-appearance 8s forwards ease-in-out" }}>
            Вы довольны ей?
          </h1>
          <h1
            style={{ animation: "slide-appearance 10s forwards ease-in-out" }}
          >
            А может вы на самом деле хотели бы жить иначе?
          </h1>
        </div>
      );
    } else if (!isPrepaired) {
      return (
        <div className="notify_container">
          <h1 className="notify_title prepaire_anim">
            Вы можете стать другим человеком.
          </h1>
          <button
            className="start_button prepaire_anim"
            onClick={() => {
              setIsPrepaired(true);
            }}
          >
            Сделать выбор
          </button>
        </div>
      );
    }
  } else if (!globalProfile["social_role"] || !globalProfile["hidden_role"]) {
    if (!extraRoleSlideIsShown) {
      return (
        <div
          className={
            "notify_title" + " " + (!extraRoleSlideIsShown ? "slide-title" : "")
          }
        >
          <span>Напоминание</span>
          <h1>Каждый Ваш выбор имеет последствия.</h1>
        </div>
      );
    } else {
      if (!socialRoleIsSelected) {
        return (
          <div className="main_container">
            <ul className="select_container">
              <li>Выберите социальную роль</li>
              {socialRoles.map((socialRole) => {
                return (
                  <li
                    onClick={(e) => {
                      selectRole(socialRole[0], e.currentTarget, true);
                    }}
                  >
                    <span>{socialRole[1]}</span>
                  </li>
                );
              })}
            </ul>
            <button
              className="start_button disabled"
              onClick={() => {
                if (selectedExtraRole) {
                  acceptSelectedRole(selectedExtraRole, "social");
                }
              }}
            >
              Сделать выбор
            </button>
          </div>
        );
      } else {
        return (
          <div className="main_container">
            <ul className="select_container">
              <li>Выберите скрытую роль</li>
              {hiddenRoles.map((hiddenRole) => {
                return (
                  <li
                    onClick={(e) => {
                      selectRole(hiddenRole[0], e.currentTarget, true);
                    }}
                  >
                    <span>{hiddenRole[1]}</span>
                  </li>
                );
              })}
            </ul>
            <button
              className="start_button disabled"
              onClick={() => {
                if (selectedExtraRole) {
                  acceptSelectedRole(selectedExtraRole, "hidden");
                }
              }}
            >
              Сделать выбор
            </button>
          </div>
        );
      }
    }
  }
}

export default RoleSelect;

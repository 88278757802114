import React, { useState } from "react";
import { setCookie } from "../../lib/cookieUtilits";

function FormPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);

  function auth() {
    if (name.length > 1 && email.length > 4) {
      fetch("/api/db-users.php", {
        method: "POST",
        body: JSON.stringify({
          action: "auth_user",
          name: name,
          email: email,
        }),
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "https://igra-vibor.ru",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setCookie("a_token", data.token, 3600 * 24 * 30); //1 month
            window.location.reload();
          } else {
            setErrorMessage("");
            setErrorMessage(data.message);
          }
        });
    }
  }

  if (isConfirmed) {
    return (
      <div className="form_container">
        <h1>Контакты</h1>
        <input
          placeholder="Имя"
          type="text"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <input
          placeholder="Email"
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <div className="reg_button">
          <button
            className="start_button"
            onClick={() => {
              auth();
            }}
          >
            Сделать выбор
          </button>
          <p style={{ color: "red", display: errorMessage ? "block" : "none" }}>
            {" "}
            {errorMessage}{" "}
          </p>
          <p>
            Нажимая на кнопку, вы даете согласие на обработку персональных
            данных и соглашаетесь c{" "}
            <a target={"_blank"} href="https://disk.yandex.ru/i/JYfjIUvM9U5syQ">
              политикой конфиденциальности
            </a>
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="main_container">
        <h2>Первая иммерсивная игра</h2>
        <h1>«ВЫБОР»</h1>
        <img src="/img/101.gif" />
        <div className="start_container">
          <div className="slogan">
            <p>10 игроков. 10 судеб. 10 голосов.</p>
            <p>1 победитель.</p>
            <p>
              <b>Кто достоин твоего выбора?</b>
            </p>
          </div>
          <button
            onClick={() => {
              setIsConfirmed(true);
            }}
            className="start_button"
          >
            Сделать выбор
          </button>
        </div>
        <div className="description_container">
          <h3>Описание игры</h3>
          <p>
            Иммерсивная игра демонстрирует, как люди делают свой выбор —
            эмоционально или рационально, сопереживая или оценивая, решая быть
            в большинстве или в меньшинстве. Но каким бы ни был результат,
            за него придется нести ответственность. Игра разделена на 5 раундов,
            каждый из которых заканчивается зрительским голосованием. Выиграет
            только один.
          </p>
        </div>
      </div>
    );
  }
}

export default FormPage;

import React, { useEffect, useState } from "react";
import RoleSelect from "./RoleSelect";
import FormPage from "./FormPage";

function Auth({ globalProfile, changeGlobalProfile }) {
  const [localProfile, setLocalProfile] = useState({});

  useEffect(() => {
    if (globalProfile["name"]) {
      setLocalProfile(globalProfile);
    }
  }, []);

  if (globalProfile["name"]) {
    if (
      !globalProfile["role_id"] ||
      !globalProfile["social_role"] ||
      !globalProfile["hidden_role"]
    ) {
      return (
        <RoleSelect
          localProfile={localProfile}
          setLocalProfile={setLocalProfile}
          globalProfile={globalProfile}
          changeGlobalProfile={changeGlobalProfile}
        />
      );
    } else {
      window.location.reload();
    }
  } else {
    return <FormPage />;
  }
}

export default Auth;

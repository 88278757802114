import React, { useEffect, useState } from "react";

function EndPage() {
  const [slideIsShown, setSlideIsShown] = useState(false);
  const [gameIsStarted, setGameIsStarted] = useState(false);
  const [eventData, setEventData] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setSlideIsShown(true);
    }, 5000);

    fetch("/api/db-events.php", {
      method: "POST",
      body: JSON.stringify({
        action: "get_event",
      }),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "https://igra-vibor.ru",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEventData(data.data);
      });
  }, []);

  if (!slideIsShown) {
    return (
      <div
        className={"notify_title" + " " + (!slideIsShown ? "slide-title" : "")}
      >
        <h1>Вы сделали свой выбор.</h1>
      </div>
    );
  } else {
    if (gameIsStarted) {
      return (
        <div className="notify_container-block">
          <div className="notify_title">
            <span>Ваше первое задание</span>
            <h2>Подготовьте самопрезентацию.</h2>
          </div>
          <div className="notify_container-block_buttons">
            <button className="notify_container-block_button">ОК</button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="event_container">
          <h1>Ждём вас.</h1>
          <div className="event_info">
            <div className="event_info_item">
              <span>Дата</span>
              <p>{eventData["date"]}</p>
            </div>
            <div className="event_info_item">
              <span>Адрес</span>
              <p
                style={{
                  "border-bottom": "1px solid #fff",
                  "padding-bottom": "5px",
                }}
              >
                {eventData["adress"]}
              </p>
            </div>
            <div className="event_info_item">
              <span>Пароль</span>
              <p>{eventData["password"]}</p>
            </div>
          </div>
          <button
            className="start_button"
            onClick={() => {
              setGameIsStarted(true);
            }}
          >
            Начать игру
          </button>
        </div>
      );
    }
  }
}

export default EndPage;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// remove context menu
document.oncontextmenu = cmenu;
function cmenu() {
  return false;
}
//

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

import React, { useEffect, useState } from "react";
import AdminPanel from "./components/AdminPanel";
import "./styles/reset.css";
import "./styles/App.css";
import { checkCookie, deleteCookie, setCookie } from "./lib/cookieUtilits";
import EndPage from "./components/EndPage";
import Auth from "./components/Auth/Auth";

function App() {
  const [globalProfile, setGlobalProfile] = useState({});
  const [isRegistered, setIsRegistered] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // remove go back func
  if (typeof window.history.pushState === "function") {
    window.history.pushState("jibberish", null, null);
    window.onpopstate = function () {
      window.history.pushState("newjibberish", null, null);
    };
  } else {
    var ignoreHashChange = true;
    window.onhashchange = function () {
      if (!ignoreHashChange) {
        ignoreHashChange = true;
        window.location.hash = Math.random();
      } else {
        ignoreHashChange = false;
      }
    };
  }
  useEffect(() => {
    // check cookie
    const tokenResult = checkCookie("a_token");

    if (tokenResult.ok) {
      fetch("/api/db-users.php", {
        method: "POST",
        body: JSON.stringify({
          action: "get_stats_token",
          token: tokenResult.value,
        }),
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "https://igra-vibor.ru",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setCookie("a_token", data.data["a_token"], 3600 * 24 * 30); // 1 month
            setGlobalProfile({ ...data.data });
            setIsRegistered(true);
          } else {
            deleteCookie("a_token");
            window.location.reload();
          }
          setIsLoaded(true);
        });
    } else {
      setIsLoaded(true);
    }
  }, []);

  function changeGlobalProfile(changes, doReload = false) {
    fetch("/api/db-users.php", {
      method: "POST",
      body: JSON.stringify({
        action: "change_stats_token",
        token: globalProfile["a_token"],
        changes: changes,
      }),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "https://igra-vibor.ru",
      },
    }).then(() => {
      setGlobalProfile({ ...globalProfile, ...changes });
      if (doReload) {
        window.location.reload();
      }
    });
  }

  if (isLoaded) {
    if (isRegistered) {
      if (globalProfile["is_admin"] == 1) {
        return (
          <div className="content_container">
            <AdminPanel globalProfile={globalProfile} />
          </div>
        );
      } else {
        if (
          !globalProfile["role_id"] ||
          !globalProfile["social_role"] ||
          !globalProfile["hidden_role"]
        ) {
          return (
            <div className="content_container">
              <Auth
                globalProfile={globalProfile}
                changeGlobalProfile={changeGlobalProfile}
              />
            </div>
          );
        } else {
          return (
            <div className="content_container">
              <EndPage />
            </div>
          );
        }
      }
    } else {
      return (
        <div className="content_container">
          <Auth
            globalProfile={globalProfile}
            changeGlobalProfile={changeGlobalProfile}
          />
        </div>
      );
    }
  }
}

export default App;
